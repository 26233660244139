import React from "react";
import SimulatorForm from "./form";


const SimulatorContent = () => {



  return <>
    <main id="content" role="main">

      {/* Hero */}
      <div className="content-space-t-3 content-space-t-lg-4 content-space-b-2 content-space-b-lg-3 overflow-hidden">
        <div className="container">

          <div className="row justify-content-lg-between">
            <div className="col-lg-5 mb-2 mb-lg-0">
              <div className="mb-3">
                <h4>Simulation expédition aérienne</h4>
                <span className="small">
                  Nous vous aidons à estimer le coût de livraison de vos colis.
                </span>
                <div className="card-transition-zoom-item col-lg-8 ">
                  <img
                    className="card-img small"
                    src="/assets/img/ex_volumetrique.png"
                    alt="Image Description"
                  />
                </div>

              </div>

              {/* {
                quotation && <div className="container content-space-1">
                  <div className="row">
                    <div className="col-sm-6 col-md-4 mb-2 mb-md-0">
                      <h6 className="d-block small">{showMontantText(quotation.currency)}</h6>
                      <span className="display-8 text-primary">{NumberFormat(quotation.price, quotation.currency)} </span>
                    </div>
                    <div className="col-sm-6 col-md-4 mb-2 mb-md-0">
                      <h6 className="d-block small">Poids retenu</h6>
                      <span className="display-8 text-primary">{quotation.weight} Kg</span>
                    </div>

                  </div>

                </div>
              } */}


              <h5>À quoi puis-je m'attendre ?</h5>
              {/* List */}
              <ul className="list-checked list-checked-dark mb-6">
                <li className="list-checked-item">Le poids final sera ajusté à réception du colis</li>
                <li className="list-checked-item">Le poids retenu correspond à la valeur la plus élévée entre le poids volumetrique et le poids colis</li>
                <li className="list-checked-item">Le simulateur prends en compte les frais de gestion de 5euro par colis (dans une limite de 25euros)</li>
              </ul>
              {/* End List */}
              {/* End Row */}
              <p>
                <a className="btn btn-primary btn-pointer" href="app/place-order" style={{marginTop:'20px'}}>
                  Créez une commande
                </a>
              </p>
            </div>
            {/* End Col */}
            <div className="col-lg-6">
              <div className="position-relative">
              <SimulatorForm />
                {/* SVG Shape */}
                <figure
                  className="position-absolute top-0 end-0 d-none d-md-block mt-n10"
                  style={{ width: "12rem", marginRight: "-10rem" }}
                >
                  <img
                    className="img-fluid"
                    src="/assets/svg/components/three-arrows-1.svg"
                    alt="Image Description"
                  />
                </figure>
                {/* End SVG Shape */}
                {/* SVG Shape */}
                <figure
                  className="position-absolute bottom-0 end-0 zi-n1 d-none d-md-block mb-n10"
                  style={{ width: "15rem", marginRight: "-8rem" }}
                >
                  <img
                    className="img-fluid"
                    src="/assets/svg/illustrations/grid-grey.svg"
                    alt="Image Description"
                  />
                </figure>
                {/* End SVG Shape */}
                {/* SVG Shape */}
                <figure
                  className="position-absolute bottom-0 end-0 d-none d-md-block me-n5 mb-n5"
                  style={{ width: "10rem" }}
                >
                  <img
                    className="img-fluid"
                    src="/assets/svg/illustrations/plane.svg"
                    alt="Image Description"
                  />
                </figure>
                {/* End SVG Shape */}
              </div>
            </div>
            {/* End Col */}
          </div>
          {/* End Row */}
        </div>
      </div>
      {/* End Hero */}
    </main>

  </>
}

export default SimulatorContent;
