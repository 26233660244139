import React from "react";
import Layout from "../../components/layout/Layout";
import SimulatorContent from "../../components/Simulator/SimulatorContent";
const simulator = ({}: any) => {
  return <>
  <Layout title={'Simulateur de coût d\'expédition'}>
      <SimulatorContent/>
  </Layout>
  </>
}
export default simulator;